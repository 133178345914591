<template>
    <ion-page data-pageid="tabs">
        <ion-content>
            <!-- <div
                style="
                    position: absolute;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    bottom: -20px;
                    z-index: 11;
                "
            >
                <div class="item-qrcode" style="width: 65px; height: 65px">
                    <div
                        class="
                            column
                            align-center
                            boundary
                            justify-center
                            primary--bg
                            quet-ma
                        "
                        style="border-radius: 50%"
                        @click="quetMa()"
                    >
                        <ion-icon
                            :icon="qrCodeOutline"
                            style="font-size: 36px"
                            class="white--text"
                        ></ion-icon>
                    </div>
                </div>
            </div> -->
            <ion-tabs id="tabs" @ionTabsWillChange="beforeTabChange">
                <ion-router-outlet />
                <ion-tab-bar slot="bottom" style="height: 36px">
                    <ion-tab-button tab="tab1" href="/tabs/tab1">
                        <i class="mdi mdi-home icon-mdi"></i>
                        <!-- <ion-label class="font-6">Trang chủ</ion-label> -->
                    </ion-tab-button>

                    <ion-tab-button
                        tab="tab2"
                        :href="isNhanVienBanVe ? '/Ban-Ve' : '/tabs/tab3'"
                        @click="
                            () => {
                                if (isNhanVienBanVe) {
                                    $router.push('/Ban-Ve');
                                } else {
                                    $router.push('/tabs/tab3');
                                }
                            }
                        "
                    >
                        <!-- @click="$router.push('/tabs/tab3?guidXe=00003402-0000-0000-0000-000000000000&maChuyenDi=211101172245051Z2TVA&guidChuyenDi=452158e1-23b6-4ba8-a8d4-14826f25a24d')" -->
                        <i class="mdi mdi-alpha-v-box icon-mdi"></i>
                        <!-- <ion-label class="font-6">Vé xe</ion-label> -->
                    </ion-tab-button>
                    <ion-tab-button
                        tab="tab3"
                        href="/tabs/LenhDienTu"
                        @click="$router.push('/tabs/LenhDienTu')"
                    >
                        <!-- @click="$router.push('/tabs/tab3?guidXe=00003402-0000-0000-0000-000000000000&maChuyenDi=211101172245051Z2TVA&guidChuyenDi=452158e1-23b6-4ba8-a8d4-14826f25a24d')" -->
                        <i class="mdi mdi-alpha-l-box icon-mdi"></i>
                        <!-- <ion-label class="font-6">Lệnh</ion-label> -->
                    </ion-tab-button>
                    <ion-tab-button
                        tab="tab4"
                        href="/tabs/HangHoa"
                        @click="$router.push('/tabs/HangHoa')"
                    >
                        <!-- @click="$router.push('/tabs/tab3?guidXe=00003402-0000-0000-0000-000000000000&maChuyenDi=211101172245051Z2TVA&guidChuyenDi=452158e1-23b6-4ba8-a8d4-14826f25a24d')" -->
                        <i class="mdi mdi-alpha-h-box icon-mdi"></i>
                        <!-- <ion-label class="font-6">Hàng hóa</ion-label> -->
                    </ion-tab-button>
                    <ion-tab-button
                        tab="tab5"
                        href="/Thong-Bao"
                        @click="$router.push('/Thong-Bao')"
                    >
                        <!-- :disabled="true" -->
                        <i class="mdi mdi-bell icon-mdi"></i>
                        <!-- <ion-label class="font-6">Thông báo</ion-label> -->
                    </ion-tab-button>
                    <ion-tab-button
                        tab="tab6"
                        href="/tabs/tab4"
                        @click="$router.push('/tabs/tab4')"
                    >
                        <i class="mdi mdi-account icon-mdi"></i>
                        <!-- <ion-label class="font-6">Cá nhân</ion-label> -->
                    </ion-tab-button>
                </ion-tab-bar>
            </ion-tabs>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonTabBar,
    IonTabButton,
    IonTabs,
    IonContent,
    IonLabel,
    IonIcon,
    IonRouterOutlet,
    IonPage,
} from "@ionic/vue";
import {
    home,
    notifications,
    ticket,
    person,
    qrCodeOutline,
} from "ionicons/icons";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { ref } from "vue";
import { useRouter } from "vue-router";
export default {
    name: "Tabs",
    components: {
        IonContent,
        IonLabel,
        IonTabs,
        IonTabBar,
        IonTabButton,
        IonIcon,
        IonPage,
        IonRouterOutlet,
    },
    setup() {
        let versionTab = ref(0);
        const router = useRouter();
        return {
            home,
            notifications,
            ticket,
            person,
            qrCodeOutline,
            versionTab,
            router,
        };
    },
    data() {
        return {
            isLaiXe: false,
            isNhanVienBanVe: false,
        };
    },
    computed: {
        ...mapGetters("QrCode", ["dangQuetMa"]),
    },
    methods: {
        ...mapActions("QrCode", ["quetMaQR"]),
        quetMa() {
            this.quetMaQR();
        },
        beforeTabChange() {
            this.versionTab++;
        },
    },
    created() {
        let arrPers = this.$Core.AuthApi.TokenParsed.resource_access.BanVeTaiBen
            ? this.$Core.AuthApi.TokenParsed.resource_access.BanVeTaiBen.roles
            : [];
        console.log("🚀 ~ file: tabs.vue ~ line 166 ~ created ~ arrPers", arrPers)
        this.isNhanVienBanVe = arrPers.some((e) => e == "NhanVienBanVe");
    },
};
</script>
<style scoped>
.quet-ma:active {
    background-color: #0580b8 !important;
}
.icon-mdi {
    font-size: 24px;
}
/*>>> .button-native {
    padding: 0 4px;
} */
ion-tabs {
    position: fixed !important;
}
</style>
